import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import Login from './Login';
import Logout from './Logout';
import { AccessInvitation } from './AccessInvitation';
import { EmptyLayoutRouterSwitch } from '../../../router/EmptyLayoutRouterSwitch';
import Register from './Register';

const AuthIndex: React.FC = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <EmptyLayoutRouterSwitch>
      <LayoutRouter exact path={`${path}/login`} component={Login} />
      <LayoutRouter exact path={`${path}/login/:userId/:token`} component={AccessInvitation} />
      <LayoutRouter exact path={`${path}/logout`} component={Logout} />
      <LayoutRouter exact path={`${path}/register`} component={Register} />
    </EmptyLayoutRouterSwitch>
  );
};

export default AuthIndex;
