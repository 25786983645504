/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

interface MarkdownViewerProps {
  markdown: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ markdown }) => {
  const [parsedContent, setParsedContent] = useState<string>('');

  const parseMarkdown = (md: string): string => {
    // Handle headers
    md = md.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    md = md.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    md = md.replace(/^# (.*$)/gim, '<h1>$1</h1>');

    // Handle bold
    md = md.replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>');

    // Handle italic
    md = md.replace(/\*(.*)\*/gim, '<em>$1</em>');

    // Handle links
    md = md.replace(/\[([^\]]+)\]\(([^)]+)\)/gim, '<a href="$2">$1</a>');

    // Handle blockquotes
    md = md
      .replace(/^\> (.+)$/gim, (match, p1) => `<blockquote style="border-left: 4px solid #ccc; 
      margin-left: 0; padding-left: 16px; color: #666;">${p1}</blockquote>`);

    // Handle lists
    md = md.replace(/^\s*\n\*/gim, '<ul>\n*');
    md = md.replace(/^(\*|\d+\.) (.*)$/gim, '<li>$2</li>');
    md = md.replace(/<\/ul>\s?<ul>/gim, '');
    md = md.replace(/(<\/li>\s?<li>)/gim, '$1\n');
    md = md.replace(/<\/ul>/gim, '</ul>\n');

    // Handle paragraphs
    md = md.replace(/^\s*(\n)?(.+)/gim, (m) => (/\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : `<p>${m}</p>`));

    // Remove empty paragraphs
    md = md.replace(/<p><\/p>/gim, '');

    // Combine adjacent blockquotes
    md = md.replace(/<\/blockquote>\s?<blockquote>/gim, '\n');

    return md.trim();
  };

  React.useEffect(() => {
    setParsedContent(parseMarkdown(markdown));
  }, [markdown]);

  return (
    // eslint-disable-next-line react/no-danger
    <div className="markdown-viewer" dangerouslySetInnerHTML={{ __html: parsedContent }} />
  );
};

export default MarkdownViewer;
