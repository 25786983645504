import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { green, red } from '@mui/material/colors';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import PageTitle from '../../ui/PageTitle';
import 'chartjs-plugin-labels';
import { AdminDashboardBanners } from '../../ui/AdminDashboardBanners';
import {
  useEvaluationResultsQuery,
  useEvaluationRunsQuery,
  useGetAdminBannersQuery,
  useGetEvaluationChartDataQuery,
} from '../../../queries/adminDashboard';
import AiEvaluationChart from '../../partials/adminDashboard/EvaluationChart';
import { useGetAIModels } from '../../../queries/gpt';
import { AiEvaluatonResult } from '../../../types/aiPrompt';
import { formatDateTimeToLocal } from '../../../formatters/dateFormatters/dateFormatters';

const getEvaluationDescription = (evaluation: string) => {
  let description = '';

  if (evaluation === '(A)') {
    description = '(A) The answer is a subset of the ground thruth and is fully consistent with it.';
  } else if (evaluation === '(B)') {
    description = '(B) The answer is a superset of the ground thruth and is fully consistent with it.';
  } else if (evaluation === '(C)') {
    description = '(C) The answer contains all the same details as the ground thruth.';
  } else if (evaluation === '(D)') {
    description = '(D) There is a disagreement between the answer and the ground thruth.';
  } else if (evaluation === '(E)') {
    // eslint-disable-next-line max-len
    description = '(E) The answer and ground thruth differ, but these differences don\'t matter from the perspective of factuality.';
  } else {
    description = '';
  }

  return description;
};

const AdminDashboard: React.FC = (): ReactElement => {
  const [resultsPagination, setResultsPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [runsPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [aiModel, setAiModel] = React.useState('');
  const [selectedEvalId, setSelectedEvalId] = React.useState(-1);
  const [rowCount, setRowCount] = useState(0);
  const { data: aiModels } = useGetAIModels();

  const { data: adminBanners, error: bannersError, isLoading: bannersLoader } = useGetAdminBannersQuery();
  const { data: evaluationChartData } = useGetEvaluationChartDataQuery();
  // eslint-disable-next-line max-len
  const { data: evaluations } = useEvaluationRunsQuery((runsPagination.pageIndex + 1), runsPagination.pageSize);
  // eslint-disable-next-line max-len
  const {
    data: evaluationResults, isLoading: isResultsLoading,
  } = useEvaluationResultsQuery(selectedEvalId, aiModel, (resultsPagination.pageIndex + 1), resultsPagination.pageSize);

  useEffect(() => {
    if (evaluations) {
      setSelectedEvalId(evaluations.items[0].id);
    }
  }, [evaluations]);

  useEffect(() => {
    if (evaluationResults) {
      setRowCount(evaluationResults.total);
    }
  }, [evaluationResults]);

  useEffect(() => {
    if (aiModels) {
      if (aiModels.length > 0) {
        setAiModel(aiModels[0].model);
      }
    }
  },
  [aiModels]);

  const handelModelsDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAiModel(event.target.value as string);
  };

  const handleEvalDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEvalId(event.target.value as number);
  };

  const columns = useMemo<MRT_ColumnDef<AiEvaluatonResult>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        size: 50,
        style: { whiteSpace: 'nowrap' },
      },
      {
        header: 'Question',
        size: 300,
        accessorKey: 'question',
        style: { whiteSpace: 'nowrap' },
        accessorFn: (row) => row.question.question,
      },
      {
        header: 'Ground Truth',
        accessorKey: 'groundTruth',
        size: 300,
        style: { whiteSpace: 'nowrap' },
        accessorFn: (row) => row.question.answer,
      },
      {
        header: 'Answer',
        accessorKey: 'answer',
        size: 300,
      },
      {
        header: 'Evaluation',
        accessorKey: 'evaluation',
        size: 100,
        // eslint-disable-next-line react/display-name
        accessorFn: (row) => (
          // eslint-disable-next-line max-len
          <Tooltip
            title={(
              <h1 style={{ fontSize: '12px' }}>
                {' '}
                {getEvaluationDescription(row.evaluation) || ''}
                {' '}
              </h1>
            )}
            placement="top"
            arrow
          >
            <Typography
              style={{ fontSize: '18px', cursor: 'pointer' }}
              noWrap
            >
              {row.evaluation}
            </Typography>
          </Tooltip>
        ),
      },
      {
        header: 'Passed',
        accessorKey: 'passed',
        size: 100,
        accessorFn: (row) => (row.passed ? 'Yes' : 'No'),
      },
      {
        header: 'Model',
        accessorKey: 'model',
        size: 100,
      },
    ],
    [],
  );

  return (
    <>
      <Box mb={3}>
        <PageTitle>Admin Dashboard</PageTitle>
      </Box>
      <AdminDashboardBanners data={adminBanners} isLoading={bannersLoader} error={bannersError} />
      <Box mb={3}>
        <Grid container spacing={3}>
          {evaluationChartData && (
            <Grid item xs={12}>
              <AiEvaluationChart chartData={evaluationChartData} />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={10}>
          <Grid item xs={3} md={3}>
            <Box mb={3}>
              <InputLabel shrink={!!evaluations} htmlFor="filter-evaluations">Select Evaluation</InputLabel>
              <FormControl margin="none" style={{ width: '250px' }}>
                <Select
                  value={evaluations?.items?.[0]?.id || 0}
                  onChange={handleEvalDropDownChange}
                  label="Select Evaluation"
                >
                  { evaluations?.items?.map((evaluation) => (
                    <MenuItem
                      key={evaluation.id}
                      value={evaluation.id}
                    >
                      {formatDateTimeToLocal(evaluation.createdAt)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box mb={3}>
              <InputLabel shrink={!!aiModel} htmlFor="filter-models">Filter By Model</InputLabel>
              <FormControl margin="none" style={{ width: '200px' }}>
                <Select
                  value={aiModel}
                  onChange={handelModelsDropDownChange}
                  label="Filter By Model"
                >
                  { aiModels?.map((model) => (
                    <MenuItem
                      key={model.model}
                      disabled={!model.active}
                      value={model.model}
                    >
                      {model.model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: '100%' }}>
        <MaterialReactTable
          columns={columns}
          data={evaluationResults?.items || []}
          enableTopToolbar={false}
          enableColumnResizing
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableRowOrdering={false}
          enableSorting={false}
          enableGlobalFilterModes={false}
          enableDensityToggle={false}
          state={{ pagination: resultsPagination, isLoading: isResultsLoading }}
          manualPagination
          rowCount={rowCount}
          onPaginationChange={setResultsPagination}
          layoutMode="grid"
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
              display: 'table',
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              backgroundColor: row.original.passed ? green[50] : red[50],
            },
          })}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
                margin: 'auto',
                width: '100%',
              }}
            >
              <Typography style={{ fontWeight: 'bold', marginTop: '20px' }}>
                Ground truth SQL Query:
              </Typography>
              <Typography>
                {row.original.question.sqlQuery}
              </Typography>
              <Typography style={{ fontWeight: 'bold', marginTop: '20px' }}>
                SQL Query:
              </Typography>
              <Typography>
                {row.original.sqlQuery}
              </Typography>
            </Box>
          )}
        />
      </Box>
    </>
  );
};

export default AdminDashboard;
