import React, {
  ReactElement, useContext, useEffect,
} from 'react';
import {
  Box, Button, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CloseSharp, Delete, Edit } from '@material-ui/icons';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useAllUsersQuery } from '../../../queries/users';
import PageTitle from '../../ui/PageTitle';
import useSuccessModal from '../../modals/SuccessModal/hooks';
import { Store } from '../../../store/AppGlobalContext';
import { accessToken } from '../../../models/security';
import { useNavigationManager } from '../../../navigation/navigationManager';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { User, UserRole, userRoleOptions } from '../../../types/users';
import { generalSorting } from '../../../helpers/sorting';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import { useDeactivateUserMutation } from '../../../queries/users/mutations';

const ListUsers: React.FC = (): ReactElement => {
  const { showModal: showDeleteModel, RenderModal: RenderDeletedModal, modalId } = useDeleteModal();
  const { state } = useContext(Store);
  const { data: users, isLoading, isFetching } = useAllUsersQuery();
  const deactivateUserMutation = useDeactivateUserMutation();
  const buttonClasses = useButtonsStyles();
  const { usersNavigator } = useNavigationManager();
  const { RenderModal, showModal } = useSuccessModal();

  const userRole = new UserRole(userRoleOptions);

  const deleteUser = (userId: number | null) => {
    if (!userId) {
      return;
    }
    deactivateUserMutation.mutate({ userId });
  };

  useEffect(() => {
    if (state.successModal) {
      showModal();
    }
  }, [state.successModal]);

  const navigateToEditUser = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    usersNavigator.edit(Number(id)).navigate();
  };

  const columns: MRT_ColumnDef<User>[] = [
    {
      header: 'Full Name',
      accessorKey: 'fullName',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.fullName || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row.fullName || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Tooltip title={row.email || ''} placement="top" arrow>
          <Typography
            style={{ cursor: 'pointer' }}
            noWrap
          >
            {row.email || ''}
          </Typography>
        </Tooltip>
      ),
      enableSorting: false,
    },
    {
      header: 'page views',
      accessorKey: 'pageViews',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (row.user_activity?.pageViews ? row.user_activity?.pageViews : 0),
      enableSorting: true,
      sortingFn: (rowA, rowB) => generalSorting(rowA.original.user_activity?.pageViews || 0,
        rowB.original.user_activity?.pageViews || 0),
    },
    {
      header: 'Last Login',
      accessorKey: 'lastLogin',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (row.user_activity?.lastSession ? row.user_activity?.lastSession : 'Never'),
      enableSorting: false,
    },
    {
      header: 'Role',
      accessorKey: 'roleType',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => userRole.getDisplayNameByKey(row.roleType),
      enableSorting: false,
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            onClick={
              (e) => navigateToEditUser(row.id, e)
            }
            aria-label="edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showDeleteModel(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      enableSorting: false,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <PageTitle>Users</PageTitle>
        {accessToken.isCurrentRoleAdmin()
              && (
              <Button
                className={buttonClasses.large}
                component={Link}
                to={usersNavigator.create().path}
                variant="contained"
                color="primary"
              >
                Create User
              </Button>
              )}
      </Box>
      <MaterialReactTable
        columns={columns}
        data={users || []}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        state={{ isLoading: isLoading || isFetching }}
        initialState={{ columnVisibility: { id: !accessToken.isCurrentRoleUser() } }}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: 'pointer',
          },
        })}
      />
      {isLoading && (
        <RenderModal
          body="A new user has been successfully created and invited to the portal."
          title="Success!"
          cancelText={<CloseSharp />}
        />
      )}
      <RenderDeletedModal
        body="Are you sure you want to delete this User?"
        confirmAction={() => deleteUser(modalId)}
        title="Delete User"
      />
    </>
  );
};

export default ListUsers;
