import React, { ReactElement, useEffect, useState } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ChatIcon from '@material-ui/icons/Chat';
import { useLocation } from 'react-router-dom';
import { accessToken } from '../../models/security';
import ListItemLink from './ListItemLink';
import { useNavigationManager } from '../../navigation/navigationManager';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export const NavigationDrawer: React.FC = (): ReactElement => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    portfolioNavigator,
    assetsNavigator,
    tenantsNavigator,
    leasesNavigator,
    buildingsNavigator,
    dataPointsNavigator,
    usersNavigator,
    newsNavigator,
    dealsNavigator,
    aiPromptsNavigator,
    adminDashboardNavigator,
    chatNavigator,
  } = useNavigationManager();

  const [sidebarTabs, setSideBarTabs] = useState([
    {
      to: portfolioNavigator.base().path,
      primary: 'Portfolio',
      icon: <DashboardIcon />,
      // hidden: accessToken.isCurrentRoleBroker(),
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: assetsNavigator.base().path,
      primary: 'Assets',
      icon: <HomeWorkIcon />,
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: buildingsNavigator.base().path,
      primary: 'Buildings',
      icon: <ApartmentIcon />,
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: tenantsNavigator.base().path,
      primary: 'Tenants',
      icon: <BusinessCenterIcon />,
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: leasesNavigator.base().path,
      primary: 'Leases',
      icon: <DescriptionIcon />,
      // hidden: accessToken.isCurrentRoleBroker(),
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: dealsNavigator.base().path,
      primary: 'Deals',
      icon: <QuestionAnswerIcon />,
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: usersNavigator.base().path,
      primary: 'Users',
      icon: <PeopleIcon />,
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: newsNavigator.base().path,
      primary: 'News',
      icon: <AssignmentIcon />,
      // hidden: accessToken.isCurrentRoleBroker(),
      hidden: !accessToken.isCurrentRoleAdmin(),
    },
    {
      to: chatNavigator.base().path,
      primary: 'Chat',
      icon: <ChatIcon />,
    },
  ]);

  const routeIncludesPath = (path: string): boolean => {
    const urlParticles = pathname.split('/');
    const urlBase = urlParticles[1];
    return path.includes(urlBase);
  };

  const addAdminRelatedTabs = () => {
    const dataPoints = 'Data Points';
    const dataPointsTab = sidebarTabs.find((tab) => tab.primary === dataPoints);
    if (!dataPointsTab && accessToken.isCurrentRoleAdmin()) {
      setSideBarTabs((prevState) => [
        ...prevState,
        {
          to: dataPointsNavigator.base().path,
          primary: dataPoints,
          icon: <EqualizerIcon />,
        },
      ]);
    }

    const aiPrompts = 'AI Prompts';
    const aiPromptsTab = sidebarTabs.find((tab) => tab.primary === aiPrompts);
    if (!aiPromptsTab && accessToken.isCurrentRoleAdmin()) {
      setSideBarTabs((prevState) => [
        ...prevState,
        {
          to: aiPromptsNavigator.base().path,
          primary: aiPrompts,
          icon: <ChatIcon />,
        },
      ]);
    }

    const adminDashboard = 'Admin Dashboard';
    const adminDashboardTab = sidebarTabs.find((tab) => tab.primary === adminDashboard);
    if (!adminDashboardTab && accessToken.isCurrentRoleAdmin()) {
      setSideBarTabs((prevState) => [
        ...prevState,
        {
          to: adminDashboardNavigator.base().path,
          primary: adminDashboard,
          icon: <DashboardIcon />,
        },
      ]);
    }
  };

  useEffect(() => {
    addAdminRelatedTabs();
  }, []);

  return (
    <>
      <div className={classes.toolbar}>
        <Typography variant="h6" noWrap>
          TAI
        </Typography>
      </div>
      <Divider />
      <List>
        {sidebarTabs.map((tab) => (
          <ListItemLink
            key={tab.primary}
            icon={tab.icon}
            isSelected={routeIncludesPath(tab.to)}
            primary={tab.primary}
            to={tab.to}
            hidden={tab.hidden}
          />
        ))}
      </List>
    </>
  );
};
