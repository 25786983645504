/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import BuildingsIndex from '../components/pages/buildings/BuildingsIndex';
import TenantsIndex from '../components/pages/tenants/TenantsIndex';
import AuthIndex from '../components/pages/auth/AuthIndex';
import UsersIndex from '../components/pages/users/UsersIndex';
import LeasesIndex from '../components/pages/leases/LeasesIndex';
import PortfolioIndex from '../components/pages/portfolio/PortfolioIndex';
import NewsIndex from '../components/pages/news/NewsIndex';
import RouterBaseSwitch from './RouterBaseSwitch';
import AssetsIndex from '../components/pages/assets/AssetsIndex';
import { LayoutRouter } from './LayoutRouter';
import { ListDataPoints } from '../components/pages/tenants/ListDataPoints';
import { AuthRoles } from '../types/auth';
import { useNavigationManager } from '../navigation/navigationManager';
import { NavigableLayoutRouterSwitch } from './NavigableLayoutRouterSwitch';
import NotFoundPage from '../components/pages/NotFoundPage';
import EditProfile from '../components/pages/auth/EditProfile';
import DealsIndex from '../components/pages/deals/DealsIndex';
import { accessToken } from '../models/security';
import { ListAiPrompts } from '../components/pages/aiPrompts/ListAiPrompts';
import AdminDashboardIndex from '../components/pages/adminDashboard/adminDashboardIndex';
import GptUI from '../components/ui/GptUI';

export const RouteWrapper: React.FC = (): JSX.Element => {
  const {
    portfolioNavigator,
    buildingsNavigator,
    assetsNavigator,
    tenantsNavigator,
    leasesNavigator,
    usersNavigator,
    authNavigator,
    newsNavigator,
    dataPointsNavigator,
    dealsNavigator,
    aiPromptsNavigator,
    adminDashboardNavigator,
    chatNavigator,
  } = useNavigationManager();

  const navigableDisplayPaths = () => [
    portfolioNavigator.base().path,
    buildingsNavigator.base().path,
    assetsNavigator.base().path,
    tenantsNavigator.base().path,
    leasesNavigator.base().path,
    dealsNavigator.base().path,
    usersNavigator.base().path,
    newsNavigator.base().path,
    dataPointsNavigator.base().path,
    aiPromptsNavigator.base().path,
    adminDashboardNavigator.base().path,
    chatNavigator.base().path,
    `${authNavigator.base().path}/edit-profile`,
  ];

  return (

    <Router>
      <RouterBaseSwitch>
        {accessToken.isCurrentRoleAdmin()
          ? <Route exact path="/"><Redirect to={portfolioNavigator.base().path} /></Route>
          : <Route exact path="/"><Redirect to={chatNavigator.base().path} /></Route>}
        <Route path={navigableDisplayPaths()}>
          <NavigableLayoutRouterSwitch>
            {(accessToken.isCurrentRoleAdmin())
              ? (
                <>
                  <LayoutRouter
                    exact
                    path={dataPointsNavigator.base().path}
                    authorizedUsers={[AuthRoles.Admin]}
                    component={ListDataPoints}
                    isPrivate
                  />
                  <LayoutRouter
                    exact
                    path={aiPromptsNavigator.base().path}
                    authorizedUsers={[AuthRoles.Admin]}
                    component={ListAiPrompts}
                    isPrivate
                  />
                  <Route path={adminDashboardNavigator.base().path} component={AdminDashboardIndex} />
                  <Route path={portfolioNavigator.base().path} component={PortfolioIndex} />
                  <Route path={buildingsNavigator.base().path} component={BuildingsIndex} />
                  <Route path={assetsNavigator.base().path} component={AssetsIndex} />
                  <Route path={tenantsNavigator.base().path} component={TenantsIndex} />
                  <Route path={leasesNavigator.base().path} component={LeasesIndex} />
                  <Route path={dealsNavigator.base().path} component={DealsIndex} />
                  <Route path={usersNavigator.base().path} component={UsersIndex} />
                  <Route path={newsNavigator.base().path} component={NewsIndex} />
                  <Route path={chatNavigator.base().path} component={GptUI} />
                </>
              )
              : <Route path={chatNavigator.base().path} component={GptUI} />}
            {accessToken.isCurrentRoleAdmin()
                && (
                  <>
                    <LayoutRouter
                      exact
                      path={dataPointsNavigator.base().path}
                      authorizedUsers={[AuthRoles.Admin]}
                      component={ListDataPoints}
                      isPrivate
                    />
                    <LayoutRouter
                      exact
                      path={aiPromptsNavigator.base().path}
                      authorizedUsers={[AuthRoles.Admin]}
                      component={ListAiPrompts}
                      isPrivate
                    />
                  </>
                )}
            <LayoutRouter
              exact
              path={`${authNavigator.base().path}/edit-profile`}
              component={EditProfile}
              authorizedUsers={[AuthRoles.Admin, AuthRoles.Broker, AuthRoles.User]}
              isPrivate
            />
          </NavigableLayoutRouterSwitch>
        </Route>
        <Route path={authNavigator.base().path} component={AuthIndex} />
        <Route
          path="*"
          render={() => <NotFoundPage />}
        />
      </RouterBaseSwitch>
    </Router>
  );
};
