import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Typography, IconButton, TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { ContentCopy, DoNotDisturb } from '@mui/icons-material';
import { Edit } from '@material-ui/icons';
import { useQueryClient } from 'react-query';
import PageTitle from '../../ui/PageTitle';
import { useDownloadAiPrompts, useGetAllAiPromptsQuery } from '../../../queries/aiPrompts';

import { AiPrompt, AiPromptListResponse } from '../../../types/aiPrompt';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import Modal from '../../modals/Modal/Modal';
import { UserFeedbackRequest } from '../../../queries/gpt/types';
import { useUserFeedbackMutation } from '../../../queries/gpt/mutations';
import GptDataGrid from '../../ui/GptDataGrid';

export const ListAiPrompts: React.FC = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [feedback, setFeedback] = useState<number>(1);
  const [feedbackComment, setFeedbackComment] = useState<string>('');
  const [feedbackPromptId, setFeedbackPromptId] = useState<number>(-1);
  const [feedbackChatId, setFeedbackChatId] = useState<number>(-1);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const buttonClasses = useButtonsStyles();
  const userFeedbackMutation = useUserFeedbackMutation();
  const { data: aiPromptsData, isLoading, isFetching } = useGetAllAiPromptsQuery(
    (pagination.pageIndex + 1), pagination.pageSize,
  );

  const queryClient = useQueryClient();
  const feedbackTypes = [
    {
      value: 1,
      label: 'Perfect answer',
    },
    {
      value: 2,
      label: 'Answer correct but incomplete',
    },
    {
      value: 3,
      label: 'Incorrect answer but close',
    },
    {
      value: 4,
      label: 'Incorrect answer way off',
    },
    {
      value: 5,
      label: 'No answer/Error',
    },
  ];

  const getFeedbackByValue = (value: number) => feedbackTypes.find((f) => f.value === value);

  const handelFeedbackDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFeedback(Number(event.target.value));
  };

  useEffect(() => {
    if (aiPromptsData) {
      setRowCount(aiPromptsData.total);
    }
  }, [aiPromptsData]);

  const download = () => {
    useDownloadAiPrompts(
      (pagination.pageIndex + 1), pagination.pageSize,
    );
  };

  const resetAfterFeedback = () => {
    setOpenFeedbackModal(false);
    setFeedbackComment('');
    setFeedbackPromptId(-1);
    setFeedbackChatId(-1);
  };

  const handleFeedbackSubmit = async () => {
    const page = pagination.pageIndex + 1;
    const perPage = pagination.pageSize;

    try {
      const request: UserFeedbackRequest = {
        feedback,
        feedbackComment,
      };
      const response = await userFeedbackMutation.mutateAsync(
        {
          request, question_id: feedbackPromptId, chat_id: feedbackChatId,
        },
      );
      const prevData = queryClient.getQueryData<AiPromptListResponse>(['aiPrompts', page, perPage]);
      if (prevData) {
        const updatedPrevData = { ...prevData };
        updatedPrevData.items = updatedPrevData.items.map((question) => {
          const updatedQuestion = { ...question };
          if (updatedQuestion.id === feedbackPromptId) {
            if (response.feedback !== undefined) {
              updatedQuestion.feedback = response.feedback;
            }
            if (response.feedbackComment !== undefined) {
              updatedQuestion.feedbackComment = feedbackComment;
            }
            return updatedQuestion;
          }
          return updatedQuestion;
        });
        queryClient.setQueryData<AiPromptListResponse>(['aiPrompts', page, perPage], updatedPrevData);
      }
      resetAfterFeedback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      resetAfterFeedback();
    }
  };

  const handleFeedback = (feedbackType: number, Comment: string, PromptId: number, ChatId: number) => {
    setOpenFeedbackModal(true);
    setFeedback(feedbackType);
    setFeedbackChatId(ChatId);
    setFeedbackPromptId(PromptId);
    setFeedbackComment(Comment);
  };

  const drawTable = (row: AiPrompt) => {
    const data = JSON.parse(row.answer);
    return (
      <GptDataGrid
        columns={data.Columns}
        data={data.Data}
        index={data.Index}
      />
    );
  };

  const columns = useMemo<MRT_ColumnDef<AiPrompt>[]>(
    () => [
      {
        header: 'Chat ID',
        accessorKey: 'chatId',
        size: 100,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex' },
        },
        style: { whiteSpace: 'nowrap' },
      },
      {
        header: 'User',
        size: 100,
        enableClickToCopy: true,
        accessorFn: (row) => `${row.aiChat.user.firstName} ${row.aiChat.user.lastName}`,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex' },
        },
        style: { whiteSpace: 'nowrap' },
      },
      {
        header: 'Question',
        accessorKey: 'question',
        size: 600,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex' },
        },
        style: { whiteSpace: 'nowrap' },
      },
      {
        header: 'Answer',
        accessorKey: 'answer',
        size: 600,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
        accessorFn: (row) => (row.answerType === 'TEXT' ? row.answer : 'Check details panel'),
      },
      {
        header: 'Feedback',
        accessorKey: 'feedback',
        size: 100,
        // eslint-disable-next-line react/display-name
        accessorFn: (row) => {
          if (row.feedback === 1) {
            return '(1) Perfect answer';
          } if (row.feedback === 2) {
            return '(2) Answer correct but incomplete';
          } if (row.feedback === 3) {
            return '(3) Incorrect answer but close';
          } if (row.feedback === 4) {
            return '(4) Incorrect answer way off';
          } if (row.feedback === 5) {
            return '(5) No answer/Error';
          }
          return <DoNotDisturb />;
        },
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        size: 100,
        // eslint-disable-next-line react/display-name
        accessorFn: (row) => new Date(row.createdAt).toLocaleString(),
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
      },
      {
        header: 'Action',
        accessorKey: 'action',
        size: 100,
        // eslint-disable-next-line react/display-name
        accessorFn: (row) => (
          <>
            <IconButton
              style={{ width: '25px' }}
              onClick={(e) => {
                e.stopPropagation();
                handleFeedback(row.feedback, row.feedbackComment, row.id, row.chatId);
              }}
              aria-label="edit"
            >
              <Edit />
            </IconButton>
          </>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>AI Prompts</PageTitle>
        <Button
          className={buttonClasses.large}
          variant="contained"
          color="default"
          onClick={download}
        >
          Download CSV
        </Button>
      </Box>
      <Box style={{ height: '100%' }}>
        <MaterialReactTable
          columns={columns}
          data={aiPromptsData?.items || []}
          enableTopToolbar={false}
          enableColumnResizing
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableRowOrdering={false}
          enableSorting={false}
          enableGlobalFilterModes={false}
          enableDensityToggle={false}
          state={{ pagination, isLoading: isLoading || isFetching }}
          manualPagination
          rowCount={rowCount}
          onPaginationChange={setPagination}
          layoutMode="grid"
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
              display: 'table',
            },
          }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
                margin: 'auto',
                width: '100%',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {`Asked By: ${row.original.aiChat.user.firstName} ${row.original.aiChat.user.lastName}`}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Question: ${row.original.question}`}
              </Typography>
              {
                row.original.answerType !== 'TEXT'
                  ? (
                    <>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Answer:
                      </Typography>
                      <Typography style={{ marginTop: '20px' }}>
                        {row.original.answerType === 'TEXT'
                          ? row.original.answer
                          : drawTable(row.original)}
                      </Typography>

                    </>
                  )
                  : (
                    <>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Answer:
                      </Typography>
                      <Typography style={{ marginTop: '20px' }}>
                        {row.original.answer}
                      </Typography>
                    </>
                  )
              }
              <Typography style={{ fontWeight: 'bold', marginTop: '20px' }}>
                SQL Query:
              </Typography>
              <Typography>
                {row.original.sqlQuery}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Feedback : ${row.original.feedback === 1 ? '(1) Perfect answer'
                  : row.original.feedback === 2 ? '(2) Answer correct but incomplete'
                    : row.original.feedback === 3 ? '(3) Incorrect answer but close'
                      : row.original.feedback === 4 ? '(4) Incorrect answer way off'
                        : row.original.feedback === 5 ? '(5) No answer/Error'
                          : 'No feedback provided'}`}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Feedback Comment: ${row.original.feedbackComment}`}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Created at: ${row.original.createdAt}`}
              </Typography>
            </Box>
          )}
        />
      </Box>
      <Modal
        confirmColor="secondary"
        title={
          (
            <Box style={{ display: 'flex' }}>
              <Box>Provide Feedback</Box>
            </Box>
          )
        }
        body={(
          <Box style={{ overflowY: 'hidden' }}>
            <FormControl margin="none" style={{ width: '540px' }}>
              <Select
                value={getFeedbackByValue(feedback)?.value}
                onChange={handelFeedbackDropDownChange}
              >
                {feedbackTypes.map((f) => (
                  <MenuItem
                    key={f.value}
                    value={f.value}
                  >
                    {f.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <TextField
              value={feedbackComment || ''}
              variant="outlined"
              label="Feedback comment"
              style={{ width: '350px', marginTop: '10px' }}
              onChange={(e) => setFeedbackComment(e.target.value)}
            />
          </Box>
        )}
        isOpen={openFeedbackModal}
        handleClose={() => { resetAfterFeedback(); }}
        confirmText="Submit feedback"
        confirmAction={() => handleFeedbackSubmit()}
        cancelText="Cancel"
        cancelAction={() => { resetAfterFeedback(); }}
      />
    </>
  );
};
