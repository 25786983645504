import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RegisterForm from '../../forms/auth/RegisterForm/RegisterForm';

const Register: React.FC = (): ReactElement => {
  const accessToken = localStorage.getItem('accessToken');
  const history = useHistory();

  useEffect(() => {
    if (accessToken) {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <>
      <RegisterForm />
    </>
  );
};

export default Register;
