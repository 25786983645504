import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Card, FormControl, FormHelperText, InputLabel, OutlinedInput, Select,
} from '@material-ui/core';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useHistory } from 'react-router-dom';
import useRegisterForm from './hooks';
import { useCardStyles } from '../../../../styles/useCardStyles';
import Alert from '../../../ui/Alert';
import PageTitle from '../../../ui/PageTitle';
import { useButtonsStyles } from '../../../../styles/useButtonsStyles';
import { RegisterFormValues } from './types';

const RegisterForm: React.FC = (): ReactElement => {
  const classes = useCardStyles();
  const { sendInviteAccess, formAlert } = useRegisterForm();
  const buttonClasses = useButtonsStyles();
  const history = useHistory();

  const initialValues: RegisterFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    industry: '',
    reason: '',
    company: '',
    role: 'user',
  };

  return (
    < >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: RegisterFormValues, actions: FormikHelpers<RegisterFormValues>) => {
          await sendInviteAccess(values, actions);
        }}
      >
        {(props: FormikProps<RegisterFormValues>) => {
          const {
            values,
            errors,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Card className={classes.card}>
              <PageTitle>Register</PageTitle>
              <Form noValidate>
                <FormControl fullWidth>
                  <TextField
                    value={values.firstName}
                    onChange={handleChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    value={values.lastName}
                    onChange={handleChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    value={values.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    value={values.company}
                    onChange={handleChange}
                    error={!!errors.company}
                    helperText={errors.company}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="company"
                    label="Company"
                    name="company"
                    autoComplete="company"
                  />
                </FormControl>
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel
                    shrink={!!values.industry}
                    htmlFor="status-label"
                    id="industry-label"
                  >
                    Industry
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput notched={!!values.industry} label="Industry" />
                    }
                    inputProps={{
                      name: 'industry',
                      id: 'industry-label',
                    }}
                    value={values.industry}
                    onChange={handleChange}
                    error={!!errors.industry}
                    label="Industry"
                    native
                  >
                    <option aria-label="None" defaultValue="" />
                    <option key="agriculture" value="Agriculture, Forestry, Fishing and Hunting">
                      Agriculture, Forestry, Fishing and Hunting
                    </option>
                    <option key="mining" value="Mining, Quarrying, and Oil and Gas Extraction">
                      Mining, Quarrying, and Oil and Gas Extraction
                    </option>
                    <option key="construction" value="Construction">
                      Construction
                    </option>
                    <option key="manufacturing" value="Manufacturing">
                      Manufacturing
                    </option>
                    <option key="wholesale-trade" value="Wholesale Trade">
                      Wholesale Trade
                    </option>
                    <option key="retail-trade" value="Retail Trade">
                      Retail Trade
                    </option>
                    <option key="transportation" value="Transportation and Warehousing">
                      Transportation and Warehousing
                    </option>
                    <option key="information" value="Information">
                      Information
                    </option>
                    <option key="finance" value="Finance and Insurance">
                      Finance and Insurance
                    </option>
                    <option key="real-estate" value="Real Estate and Rental and Leasing">
                      Real Estate and Rental and Leasing
                    </option>
                    <option key="professional" value="Professional, Scientific, and Technical Services">
                      Professional, Scientific, and Technical Services
                    </option>
                    <option key="management" value="Management of Companies and Enterprises">
                      Management of Companies and Enterprises
                    </option>
                    <option
                      key="waste-management"
                      value="Waste Management and Remediation Services"
                    >
                      Waste Management and Remediation Services
                    </option>
                    <option key="education" value="Educational Services">
                      Educational Services
                    </option>
                    <option key="health" value="Health Care and Social Assistance">
                      Health Care and Social Assistance
                    </option>
                    <option key="arts" value="Arts, Entertainment, and Recreation">
                      Arts, Entertainment, and Recreation
                    </option>
                    <option key="accommodation" value="Accommodation and Food Services">
                      Accommodation and Food Services
                    </option>
                    <option key="other" value="Other">
                      Other
                    </option>
                  </Select>
                  <FormHelperText
                    id="industry-helper-text"
                    data-testid="industry-helper-text"
                    error={!!errors.industry}
                  >
                    {errors.industry}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    value={values.reason}
                    onChange={handleChange}
                    error={!!errors.reason}
                    helperText={errors.reason}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="reason"
                    label="Reason"
                    name="reason"
                    autoComplete="reason"
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Button
                    type="submit"
                    fullWidth
                    className={buttonClasses.standard}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Register
                  </Button>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Button
                    type="button"
                    fullWidth
                    className={buttonClasses.standard}
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                    onClick={() => history.push('/auth/login')}
                  >
                    Have an account? Log in
                  </Button>
                </FormControl>
                <Alert message={formAlert.message} type={formAlert.type} />
              </Form>
            </Card>
          );
        }}
      </Formik>
    </>
  );
};

export default RegisterForm;
