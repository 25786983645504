import { ListResponse } from './pagination';
import { Tenant } from './tenant';

export interface DataPoints {
  id: number
  dataSource: string
  dataType: string
  dateEntered: string
  locationCriteria: string
  key: StatisticsKeys
  value: string
  company? : Tenant
}

export interface DataPointsListResponse extends ListResponse{
  items: DataPoints[]
}

export enum TenantSource {
  Linkedin = 'LinkedIn',
  Indeed = 'Indeed',
}

export interface TenantAggregatedData {
  monthlyRevenue: number
  leasesTotalArea: number
  employeesCountData: {
    employeesCount: number
    employeesCountChange: number
  }
  leaseEndDate: string
}

export enum StatisticsKeys {
  averageRating = 'average_rating',
  reviews = 'number_of_reviews',
  postings = 'number_of_postings',
  employees = 'number_of_employees',
}

export interface EmployeesCountChartData {
  data: EmployeesCountData[]
  location?: string
}

export interface EmployeesCountData {
  date: string
  employeesCount: number
}

export interface TenantEmployeesStatistics {
  companyName: string
  employeesCount: number
  employeesCountChange: number
  companyId: number
  leasedArea: number
}

export interface CompanyStatisticsByLocation {
  location: string
  data: TenantEmployeesStatistics[]
}

export interface NonVacantBuildingArea {
  leaseEndYear: number
  totalArea: number
  percentage: number
}

export interface ByMonthArea {
  totalArea: number
  percentage: number
}

export interface BuildingStatistics {
  vacant: number
  byYear: NonVacantBuildingArea[]
  byMonth: ByMonthArea
  emptySpace: number
}

export interface BuildingBanners {
  numberOfTenants: string
  buildingTotalArea: string
  leasesTotalArea: string
  numberOfNearExpiringLeases: string
  nearExpiringLeasedArea: string
  weightedAverageExpiryDate: string
}

export interface AggregatedCardData {
  numberOfBuildings?: string
  numberOfTenants: string
  totalLeasedArea: string
  totalBuildingsArea: string
  numberOfNearExpiringLeases: string
  nearExpiringLeasedArea: string
  weightedAverageExpiryDate: string
}

export interface AdminDashboardCardData {
  lastEvaluationDate: string
  evaluationDelta: {
    model: string
    evaluationDelta: number
  }[]
}

export interface AiEvaluationChartData {
  data: EvaluationPecentageData[]
  model: string
}

export interface EvaluationPecentageData {
  date: string
  percentage: number
}
