import React, { ReactElement, useContext } from 'react';
import {
  Box, Button, Grid, IconButton, Link,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import PageTitle from '../../ui/PageTitle';
import { useGetSingleLeaseQuery } from '../../../queries/leases';
import { InfoCards } from '../buildings/ViewBuilding/interfaces';
import InfoCard from '../../ui/InfoCard';
import { useCardStyles } from '../../../styles/useCardStyles';
import { formatAreaSize } from '../../../formatters/areaSizeFormatter';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';
import { useGetAllDocumentsQuery } from '../../../queries/documents';
import { useDeleteDocument } from '../../../queries/documents/mutations';
import useDeleteModal from '../../modals/DeleteModal/hooks';
import DocumentModal from '../../modals/DocumentModal/DocumentModal';
import { Store } from '../../../store/AppGlobalContext';
import { storeActions } from '../../../store/actions';
import { useViewLeaseStyles } from '../../../styles/useViewLeaseStyles';
import { LeaseAbstract } from '../../partials/leases/LeaseAbstract';
import { useButtonsStyles } from '../../../styles/useButtonsStyles';
import { Document } from '../../../queries/documents/types';

export interface LeaseRouteParams {
  leaseId: string
}

const ViewLease:React.FC = ():ReactElement => {
  const { state, dispatch } = useContext(Store);
  const { leaseId } = useParams<LeaseRouteParams>();
  const { data: lease } = useGetSingleLeaseQuery(Number(leaseId));
  const { data: documents, isLoading, isFetching } = useGetAllDocumentsQuery(Number(leaseId));
  const deleteDocumentMutation = useDeleteDocument();
  const cardClasses = useCardStyles();
  const buttonClasses = useButtonsStyles();
  const leaseClasses = useViewLeaseStyles();
  const {
    showModal: showDocumentDeleteModal,
    RenderModal: DocumentDeleteModal,
    modalId: documentModalId,
  } = useDeleteModal();

  const deleteDocument = (documentId: number | null) => {
    if (!documentId) {
      return;
    }
    deleteDocumentMutation.mutate({ documentId });
  };

  const handleOpenDocModal = () => {
    dispatch({ type: storeActions.OPEN_DOCUMENT_MODAL, payload: { leaseId } });
  };

  const getAllLeasedArea = () => {
    const areas = lease?.spaces.map((space) => (
      String(space.occupiedByLease)
    ));
    return areas?.reduce((a, b) => a + b);
  };

  const infoCards: InfoCards[] = [
    {
      icon: <HomeIcon fontSize="medium" />,
      iconBackground: 'secondary.main',
      title: 'TOTAL LEASE FT',
      content: formatAreaSize(String(getAllLeasedArea())),
    },
    {
      icon: <AccessAlarmsIcon fontSize="medium" />,
      iconBackground: 'warning.main',
      title: 'LEASE EXP. DATE',
      content: formatDateUSReadable(lease?.endDate),
    },
  ];

  const columns: MRT_ColumnDef<Document>[] = [
    {
      header: 'Document Name',
      accessorKey: 'title',
      size: 20,
    },
    {
      header: 'Document Type',
      accessorKey: 'type',
      size: 20,
    },
    {
      header: 'Link',
      accessorKey: 'url',
      size: 100,
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <Link href={`${row.url}`} target="_blank">{row.url}</Link>
      ),
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      // eslint-disable-next-line react/display-name
      accessorFn: (row) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              showDocumentDeleteModal(Number(row.id));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
      size: 20,
    },
  ];

  return (
    <>
      <Box className={leaseClasses.spaceBetween}>
        <Box>
          <PageTitle>
            {lease?.company?.name}
          </PageTitle>
        </Box>
        <Box mb={3}>
          <Grid container spacing={3} className={cardClasses.cardWrapper}>
            { infoCards.map((infoCard) => (
              <Grid item key={infoCard.title}>
                <InfoCard
                  icon={infoCard.icon}
                  title={infoCard.title}
                  content={infoCard.content}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <LeaseAbstract lease={lease} leaseId={leaseId} leasedArea={getAllLeasedArea()} />
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
        <PageTitle>Documents</PageTitle>
        <Button
          className={buttonClasses.standard}
          variant="contained"
          color="primary"
          onClick={() => handleOpenDocModal()}
        >
          ADD DOC
        </Button>
      </Box>
      <MaterialReactTable
        data={documents || []}
        columns={columns}
        enableTopToolbar={false}
        enableColumnResizing
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableRowOrdering={false}
        enableGlobalFilterModes={false}
        enableDensityToggle={false}
        layoutMode="grid"
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
            display: 'table',
          },
        }}
        state={{ isLoading: isLoading || isFetching }}
      />
      <DocumentDeleteModal
        body="Are you sure you want to delete this Document?"
        confirmAction={() => deleteDocument(documentModalId)}
        title="Delete Document"
      />
      {
        state.documentModal.visible
        && (
        <DocumentModal
          isOpen={state.documentModal.visible}
          title="Upload Document"
          leaseId={leaseId}
        />
        )
      }
    </>
  );
};

export default ViewLease;
