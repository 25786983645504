/* eslint-disable max-len */
import { useQuery, UseQueryResult } from 'react-query';
import httpClient from '../../core/httpClient';
import { AdminDashboardCardData, AiEvaluationChartData } from '../../types/statistics';
import { QUERY_OPTIONS } from '../../constants';
import { AiEvaluationResultsListResponse, AiEvaluationRunListResponse } from '../../types/aiPrompt';

export const useGetAdminBannersQuery = ():
UseQueryResult<AdminDashboardCardData, Error> => useQuery<AdminDashboardCardData, Error>(
  'suggestions',
  async () => {
    const response = await httpClient.get('/ai_evaluation/admin_dashboard_banner');
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetEvaluationChartDataQuery = (
): UseQueryResult<AiEvaluationChartData[], Error> => useQuery<AiEvaluationChartData[], Error>(
  ['evaluation-data'],
  async () => {
    const response = await httpClient.get('/ai_evaluation/evaluation_chart_data');
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
  },
);

export const useEvaluationRunsQuery = (
  page: number,
  perPage: number,
): UseQueryResult<AiEvaluationRunListResponse, Error> => useQuery<AiEvaluationRunListResponse, Error>(
  ['aiEvaluationRuns', page, perPage],
  async () => {
    const response = await httpClient.get(`/ai_evaluation/evaluation_runs?page=${page}&per_page=${perPage}`);
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useEvaluationResultsQuery = (
  evaluationId: number,
  model: string,
  page: number,
  perPage: number,
): UseQueryResult<AiEvaluationResultsListResponse, Error> => useQuery<AiEvaluationResultsListResponse, Error>(
  ['aiEvaluationPrompts', evaluationId, model, page, perPage],
  async () => {
    const response = await httpClient.get(`/ai_evaluation/evaluation_results/${evaluationId}?model=${model}&page=${page}&per_page=${perPage}`);
    return response.data;
  },
  {
    staleTime: 1000 * 60 * 10,
    enabled: !!evaluationId && evaluationId !== -1 && !!model && model !== '',
  },
);
