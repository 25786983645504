import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LayoutRouter } from '../../../router/LayoutRouter';
import { useAllStatesQuery } from '../../../queries/geo';
import RouterBaseSwitch from '../../../router/RouterBaseSwitch';
import AdminDashboard from './adminDashboard';
// import { ListEvaluationTests } from '../evaluations/ListEvaluationTests';

const AdminDashboardIndex:React.FC = (): ReactElement => {
  useAllStatesQuery();

  const { path } = useRouteMatch();

  return (
    <RouterBaseSwitch>
      <LayoutRouter
        exact
        path={path}
        component={AdminDashboard}
        isPrivate
      />
      {/* <Route path={`${path}/evaluations`} component={ListEvaluationTests} /> */}
    </RouterBaseSwitch>
  );
};

export default AdminDashboardIndex;
