import {
  InfiniteData, useMutation, UseMutationResult, useQueryClient,
} from 'react-query';
import httpClient from '../../core/httpClient';
import {
  AskGptVariables,
  Chat,
  CreateChatMutationVariables,
  CreateGptResponse,
  DeleteChatMutationVariables, DeleteQuestionMutationVariables, LLMResponse, UpdateUserFeedbackMutationVariables,
} from './types';

export const useAskGptMutation = ():
UseMutationResult<LLMResponse, Error, AskGptVariables> => {
  const queryClient = useQueryClient();

  return useMutation<LLMResponse, Error, AskGptVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ request }) => {
      const url = '/gpt/ask';
      const response = await httpClient.post(url, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<LLMResponse[]>(['chat-questions', variables.request.chat_id]);
        if (prevData) {
          const updatedData: LLMResponse[] = prevData.map((question) => {
            if ('suggestions' in question) {
              return { ...question, suggestions: [] };
            }
            return question;
          });
          queryClient.setQueryData<LLMResponse[]>(
            ['chat-questions', variables.request.chat_id], [...updatedData, data],
          );
        }
      },
    },
  );
};

export const useCreateChatMutation = ():
UseMutationResult<Chat, Error, CreateChatMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<Chat, Error, CreateChatMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ name }) => {
      const response = await httpClient.post('/ai_chats/create-user-chat', { name });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        const prevData = queryClient.getQueryData<InfiniteData<Chat[]>>('user-chats');
        if (prevData) {
          const newPages = prevData.pages.map((page, index) => {
            if (index === 0) {
              return [data, ...page];
            }
            return page;
          });

          queryClient.setQueryData('user-chats', {
            ...prevData,
            pages: newPages,
          });
        }
      },
    },
  );
};

export const useUserFeedbackMutation = ():
UseMutationResult<CreateGptResponse, Error, UpdateUserFeedbackMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<CreateGptResponse, Error, UpdateUserFeedbackMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ request, question_id, chat_id }) => {
      const response = await httpClient.put(`/ai_questions/user-feedback/${question_id}`, request);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<CreateGptResponse[]>(['chat-questions', variables.chat_id]);
        if (prevData) {
          queryClient.setQueryData<CreateGptResponse[]>(['chat-questions', variables.chat_id],
            prevData.map((question) => {
              if (question.id === variables.question_id) {
                return data;
              }
              return question;
            }));
        }
      },
    },
  );
};

export const useDeleteChatMutation = (): UseMutationResult<null, Error,
DeleteChatMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteChatMutationVariables>(
    async ({ chatId }) => httpClient.delete(`/ai_chats/delete-user-chat/${chatId}`),
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<InfiniteData<Chat[]>>('user-chats');
        if (prevData) {
          const newPages = prevData.pages.map((page) => page.filter((chat) => chat.id !== variables.chatId));

          queryClient.setQueryData('user-chats', {
            ...prevData,
            pages: newPages,
          });
        }
      },
    },
  );
};

export const useDeleteQuestionMutation = (): UseMutationResult<null, Error,
DeleteQuestionMutationVariables> => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, DeleteQuestionMutationVariables>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ questionId, chatId }) => httpClient.delete(`/ai_chats/delete-chat-question/${questionId}`),
    {
      onSuccess: async (data, variables) => {
        const prevData = queryClient.getQueryData<CreateGptResponse[]>(['chat-questions', variables.chatId]);
        if (prevData) {
          queryClient.setQueryData<CreateGptResponse[]>(['chat-questions', variables.chatId],
            prevData.filter((question) => question.id !== variables.questionId));
        }
      },
    },
  );
};
