import React, { ReactElement } from 'react';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import InfoCard from './InfoCard';
import ComponentError from './ComponentError';
import { AdminDashboardCardData } from '../../types/statistics';
import ComponentLoader from './ComponentLoader';
import { emptyStringValueToStringZero } from '../../formatters/emptyStringValueToStringZero';

interface DashboardBannersProps {
  data: AdminDashboardCardData | undefined
  isLoading: boolean
  error: Error | null
}

export interface InfoCards {
  icon: React.ReactElement;
  title: string,
  subtitle?: string
  content: number | string | React.ReactElement,
  subContent?: number | string
  metrics?: string
}

export const AdminDashboardBanners: React.FC<DashboardBannersProps> = (props: DashboardBannersProps): ReactElement => {
  const { data: banner, isLoading, error } = props;

  const lastEvaluationDate = (): string => {
    if (banner?.lastEvaluationDate) {
      return new Date(banner.lastEvaluationDate).toLocaleDateString();
    }
    return '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const EvaluationDelta = (evaluationDelta: any): React.ReactElement => {
    return (
      <Box>
        {evaluationDelta.evaluationDelta}
      </Box>
    );
    return <Box>0</Box>;
  };

  const infoCards: InfoCards[] = [
    {
      icon: <EqualizerIcon fontSize="medium" />,
      title: 'Last Evaluation Date',
      content: emptyStringValueToStringZero(lastEvaluationDate()),
      metrics: 'month',
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner?.evaluationDelta.map((evaluationDelta: any) => (
    infoCards.push({
      icon: <EqualizerIcon fontSize="medium" />,
      title: evaluationDelta.model,
      content: EvaluationDelta(evaluationDelta),
      metrics: '%',
    })
  ));

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        { banner && !isLoading
          && infoCards.map((infoCard) => (
            <Grid item xs={12} md={2.4} lg={2.4} xl={2.4} key={infoCard.title}>
              <InfoCard
                icon={infoCard.icon}
                title={infoCard.title}
                content={infoCard.content}
                subtitle={infoCard.subtitle}
                subContent={infoCard.subContent}
                metrics={infoCard.metrics}
              />
            </Grid>
          ))}
        {
          error && (
            <ComponentError error={error} />
          )
        }
      </Grid>
      <ComponentLoader isOpen={isLoading} />
    </Box>
  );
};
