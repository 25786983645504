import React, { ReactElement } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  AiEvaluationChartData,
  EvaluationPecentageData,
} from '../../../types/statistics';
import { generateColors } from '../../../styles/colors/palette';
import { cardTitleProps } from '../../../styles/useCardStyles';
import { formatDateUSReadable } from '../../../formatters/dateFormatters/dateFormatters';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
export interface AiEvaluationChartProps {
  chartData: AiEvaluationChartData[]
}

const getEvaluationDates = (chartData: AiEvaluationChartData[]) => chartData
  .map((dataItem: AiEvaluationChartData) => (dataItem.data.map((nestedData: EvaluationPecentageData) => nestedData.date)))
  .flat();

const getEvaluationDatesUniqueDates = (chartData: AiEvaluationChartData[]): string[] => {
  const labels = getEvaluationDates(chartData)
    .map((date) => formatDateUSReadable(date));

  const uniqueLabels = new Set([...labels]);

  return Array.from(uniqueLabels)
    .map((item) => item);
};

const getEvaluationChartDataset = (chartData: AiEvaluationChartData[]) => {
  const colors = generateColors(Number(chartData.length));

  return chartData.map((dataItem: AiEvaluationChartData, index: number) => {
    const label = dataItem.model ?? '';
    const filteredChartData: AiEvaluationChartData | undefined = chartData
      .find((nestedData: AiEvaluationChartData) => nestedData.model === label);

    return {
      type: 'line' as const,
      label,
      fill: false,
      data: filteredChartData?.data
        .map((nestedData) => ({
          x: new Date(nestedData.date),
          y: nestedData.percentage,
        })) ?? [],
      backgroundColor: colors[index],
      borderColor: colors[index],
    };
  });
};

const EvaluationPercentageOptions = {
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      max: 100,
    },
    // x: {
    //   type: 'time',
    //   time: {
    //     unit: 'day',
    //   },
    // },
  },
  layout: {
    padding: {
      left: 10,
      right: 20,
      bottom: 10,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

const AiEvaluationChart: React.FC<AiEvaluationChartProps> = (props: AiEvaluationChartProps): ReactElement => {
  const { chartData } = props;
  const EvaluationPercentageData = () => ({
    labels: chartData ? getEvaluationDatesUniqueDates(chartData) : [],
    datasets: chartData ? getEvaluationChartDataset(chartData) : [],
  });

  return (
    <Card>
      <CardHeader titleTypographyProps={cardTitleProps} title="Evaluation Percentages" />
      {
        chartData && (
        <Line options={EvaluationPercentageOptions} data={EvaluationPercentageData()} />
        )
       }
    </Card>
  );
};

export default AiEvaluationChart;
