import * as Yup from 'yup';
import { REQUIRED_FIELD } from '../../../../constants';

export const UserValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string().required(REQUIRED_FIELD),
  company: Yup.string().required(REQUIRED_FIELD),
  industry: Yup.string().required(REQUIRED_FIELD),
  reason: Yup.string().required(REQUIRED_FIELD),
  role: Yup.string().required(REQUIRED_FIELD),
});
