import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, {
  MouseEvent, ReactElement, useState,
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import {
  Hidden, MenuItem,
} from '@material-ui/core';
import { useUserProfileQuery } from '../../queries/users';
import { NavigationDrawer } from '../ui/NavigationDrawer';
import useGptModal from '../modals/GptModal/hooks';
import GptUI from '../ui/GptUI';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  contentMarginTop: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'hidden',
  },
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userName: {
    fontSize: '1.0rem',
  },
  gptBox: {
    zIndex: 9999,
    bottom: '2.0rem',
    position: 'fixed',
    left: '2.0rem',
    display: 'grid',
  },
}));

const DefaultLayout: React.FC = (props): ReactElement => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { children } = props;
  const { data: profile } = useUserProfileQuery();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showModal, RenderModal, modalId } = useGptModal();

  const toggleDrawer = () => (window.innerWidth > theme.breakpoints.values.sm
    ? setIsOpen(!isOpen) : setIsMobileOpen(!isMobileOpen));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        {/* <Box className={classes.gptBox}>
          <Fab color="primary" aria-label="add" onClick={() => showModal(modalId)}>
            <AddIcon />
          </Fab>
        </Box> */}
        <RenderModal
          body={<GptUI />}
          title=""
        />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: isOpen,
          })}
        >
          <Toolbar className={classes.navbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenMenu}
              color="inherit"
              className={classes.userName}
            >
              <div>
                { profile && `${profile.firstName} ${profile.lastName}` }
              </div>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/auth/edit-profile" onClick={handleClose}>
                Edit Profile
              </MenuItem>
              <MenuItem component={Link} to="/auth/logout">Log Out</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <nav aria-label="menu">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={isMobileOpen}
              onClose={toggleDrawer}
              classes={{
                paper: classes.drawer,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <NavigationDrawer />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: isOpen,
                  [classes.drawerClose]: !isOpen,
                }),
              }}
            >
              <NavigationDrawer />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.contentMarginTop} />
          {children}
        </main>
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
