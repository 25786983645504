import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSecurity } from '../../../../hooks/useSecurity';
import { AlertProps } from '../../../ui/Alert';
import { RegisterFormValues, UseRegisterForm } from './types';

const useRegisterForm = (): UseRegisterForm => {
  const { register } = useSecurity();
  const history = useHistory();

  const [formAlert, setFormAlert] = useState<AlertProps>({
    message: '',
    type: undefined,
  });

  const sendInviteAccess = async (values: RegisterFormValues, actions: FormikHelpers<RegisterFormValues>) => {
    setFormAlert({ message: '', type: undefined });

    try {
      await register(values);
      setFormAlert({
        message: 'You have been registered successfully',
        type: 'success',
      });
      history.push('/auth/login');
      setTimeout(() => {
        setFormAlert({
          message: '',
          type: undefined,
        });
      }, 5000);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switch ((error as any).response?.status) {
        case 422:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions.setErrors((error as any).response.data.errors);
          break;
        default:
          setFormAlert({
            message: 'Something went wrong',
            type: 'error',
          });
      }
    }
  };

  return {
    sendInviteAccess,
    formAlert,
  };
};

export default useRegisterForm;
