import {
  useQuery, UseQueryResult, UseInfiniteQueryResult, useInfiniteQuery,
} from 'react-query';
import httpClient from '../../core/httpClient';
import {
  AiChatListResponse,
  AIModels,
  Chat, CreateGptResponse, DocumentListResponse, SuggestionEntity,
} from './types';
import { QUERY_OPTIONS } from '../../constants';

export const useGetSuggestions = ():
UseQueryResult<SuggestionEntity[], Error> => useQuery<SuggestionEntity[], Error>(
  'suggestions',
  async () => {
    const response = await httpClient.get('/gpt/suggestions');
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetAIModels = ():
UseQueryResult<AIModels[], Error> => useQuery<AIModels[], Error>(
  'AI-models',
  async () => {
    const response = await httpClient.get('/ai_chats/ai_models');
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetUserChats = (
  page: number,
  perPage: number,
): UseInfiniteQueryResult<Chat[], Error> => useInfiniteQuery<Chat[], Error>(
  ['user-chats'],
  async ({ pageParam = page }) => {
    if (pageParam == null) return [];

    const response = await httpClient.get(`/ai_chats/user-chats?page=${pageParam}&per_page=${perPage}`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < perPage) {
        return null; // Indicates that there are no more pages to fetch
      }
      return allPages.length + 1; // Increment the page number for the next request
    },
  },
);

export const useGetAllChatsQuery = (
  page: number,
  perPage: number,
  setRowCount: (rowCount: number) => void,
): UseQueryResult<AiChatListResponse, Error> => useQuery<AiChatListResponse, Error>(
  ['chats', page, perPage],
  async () => {
    const response = await httpClient.get(`/ai_chats/ai-chats?page=${page}&per_page=${perPage}`);
    setRowCount(response.data.total);
    return response.data;
  },
  QUERY_OPTIONS,
);

export const useGetChatQuestions = (chat: Chat | undefined):
UseQueryResult<CreateGptResponse[], Error> => useQuery<CreateGptResponse[], Error>(
  ['chat-questions', chat?.id],
  async () => {
    const response = await httpClient.get(`/ai_chats/chat-questions/${chat?.id}`);
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    enabled: chat !== undefined,
    retry: false,
  },
);

export const useGetDocumentByTenantName = (tenantName: string):
UseQueryResult<DocumentListResponse, Error> => useQuery<DocumentListResponse, Error>(
  ['documents', tenantName],
  async () => {
    const query = {
      ...(tenantName && { tenant_name: tenantName }),
    };

    const response = await httpClient.get('/documents/tenants', {
      params: { ...query },
    });
    return response.data;
  },
  {
    ...QUERY_OPTIONS,
    keepPreviousData: true,
    enabled: !!tenantName,
  },
);
