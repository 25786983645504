import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
// import { MuiMarkdown } from 'mui-markdown';
import {
  Box, Button,
  CircularProgress,
  FormControl, IconButton,
  InputAdornment, InputLabel,
  MenuItem, Select, TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  Delete, Close, ChevronLeft, ChevronRight,
} from '@material-ui/icons';
import { MRT_PaginationState } from 'material-react-table';
import { List, ListItem } from '@mui/material';
import {
  Chat, CreateGptResponse, CreateLLMQuestionResponse, LLMResponse, QADocument, SuggestionEntity, UserFeedbackRequest,
} from '../../queries/gpt/types';
import GptDataGrid from './GptDataGrid';
import {
  useGetUserChats, useGetChatQuestions, useGetAIModels,
  useGetDocumentByTenantName,
} from '../../queries/gpt';
import AutoCompleteTextFieldProps from './AutoCompleteTextField';
import Modal from '../modals/Modal/Modal';
import { formatDateUSReadable } from '../../formatters/dateFormatters/dateFormatters';
import {
  useAskGptMutation, useCreateChatMutation, useDeleteChatMutation, useDeleteQuestionMutation, useUserFeedbackMutation,
} from '../../queries/gpt/mutations';
import useDeleteModal from '../modals/DeleteModal/hooks';
import LLMQuestion from './LLMQuestion';
import MarkdownViewer from './MarkdownViewer';

function getStyles(documentId: number, selectedDocumentIds: number[], theme: Theme) {
  return {
    fontWeight:
      selectedDocumentIds.indexOf(documentId) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 200px)',
    marginTop: '20px',
  },
  responseView: {
    padding: theme.spacing(2),
    height: 'calc(100vh - 235px)',
    backgroundColor: '#fff',
    overflow: 'auto',
  },
  inputText: {
    backgroundColor: '#fff',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    flexGrow: 1,
    width: '100%',
  },
  buttonBox: {
    height: '100%',
    position: 'relative',
  },
  sendButton: {
    height: '100%',
  },
  aiModelSwitch: {
    position: 'absolute',
    top: '-40px',
    right: '0px',
    width: '250px',
    textAlign: 'center',
    zIndex: 1000,
  },
  tipsButton: {
    float: 'right',
    marginTop: '5px',
  },
  feedBackContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  feedbackButton: {
    padding: '0px',
    width: '18px !important',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#CCC',
      color: '#000',
    },
  },
  chatBox: {
    borderRight: '1px solid #a79a9a',
    height: 'calc(100vh - 266px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  sidebar: {
    transition: 'all 0.3s',
    position: 'relative',
    flexShrink: 0,
  },
  leftSidebar: {
    width: '16.66%', // Equivalent to 2 columns in a 12-column grid
    marginRight: theme.spacing(2),
  },
  rightSidebar: {
    width: '16.66%', // Equivalent to 2 columns in a 12-column grid
    marginLeft: theme.spacing(2),
  },
  sidebarClosed: {
    width: '0',
    overflow: 'hidden',
    margin: 0,
  },
  mainContent: {
    position: 'relative',
    flex: 1,
    transition: 'all 0.3s',
  },
  sidebarToggle: {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
  },
  leftSidebarToggle: {
    left: -35,
  },
  rightSidebarToggle: {
    right: -35,
  },
  hiddenSidebar: {
    width: 0,
    padding: 0,
    overflow: 'hidden',
  },
  chatRightBox: {
    borderLeft: '1px solid #a79a9a',
    height: 'calc(100vh - 266px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: '10px',
  },
  chatMiddleBox: {
    position: 'relative',
  },
  selectDocument: {
    '&.MuiInputBase-root': {
      width: '250px', // Set your desired fixed width here
    },
    '& .MuiSelect-select': {
      width: '100%',
    },
  },
  chat: {
    position: 'relative',
  },
  chatDate: {
    position: 'absolute',
    top: '23px',
    left: '10px',
    fontSize: '10px',
  },
  deleteChat: {
    width: '25px',
    position: 'absolute',
    top: '5px',
    right: '0px',
    '&:hover': {
      color: '#000',
    },
  },
  questionBox: {
    backgroundColor: '#CCC',
    padding: '10px',
  },
  answerBox: {
    backgroundColor: '#f1f1f1',
    padding: '10px',
  },
  answerContainer: {
    marginBottom: '10px',
    position: 'relative',
    '&:hover': {
      boxShadow: '0px 0px 5px 0px #CCC',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '10px',
    '&:hover': {
      color: '#000',
    },
  },
}));

const GptUI: () => React.ReactElement = (): ReactElement => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userChatsPagination, setUserChatsPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [question, setQuestion] = React.useState('');
  const [selectedSuggestions, setSelectedSuggestions] = React.useState<SuggestionEntity[]>([]);
  const [cantSend, setCantSend] = React.useState(false);
  const [initialData, setData] = React.useState< LLMResponse[] >([]);
  const [aiModel, setAiModel] = React.useState('');
  const [openTipsModal, setOpenTipsModal] = React.useState(false);
  const [currentChat, setCurrentChat] = React.useState<Chat>();
  const [chats, setChats] = React.useState<Chat[]>([]);
  const [feedbackType, setFeedbackType] = React.useState<number>(0);
  const [selectedFeedback, setSelectedFeedback] = React.useState<number>(0);
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('');
  const [feedbackAnswerId, setFeedbackAnswerId] = React.useState<number>(0);
  const [mouseOverAnswer, setMouseOverAnswer] = React.useState<number>(0);
  const [tenantName, setTenantName] = useState<string>('');
  const [cachedDocuments, setCachedDocuments] = React.useState<QADocument[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>([]);
  const [filteredTenant, setFilteredTenant] = useState<string>('');
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);

  const { data: documents } = useGetDocumentByTenantName(filteredTenant);

  useEffect(() => {
    if (documents) {
      // Add new documents to the cache
      const newDocuments = documents.items.filter((doc) => !cachedDocuments.find((d) => d.id === doc.id));
      setCachedDocuments([...cachedDocuments, ...newDocuments]);
    }
  }, [documents]);

  const theme = useTheme();

  const positiveFeedbackTypes = [
    {
      value: 1,
      label: 'Perfect answer',
    },
    {
      value: 2,
      label: 'Answer correct but incomplete',
    },
  ];
  const negativeFeedbackTypes = [
    {
      value: 3,
      label: 'Incorrect answer but close',
    },
    {
      value: 4,
      label: 'Incorrect answer way off',
    },
    {
      value: 5,
      label: 'No answer/Error',
    },
  ];
  const { data: aiModels } = useGetAIModels();
  const {
    showModal: showDeleteQuestionModal, RenderModal: DeleteQuestionModal, modalId: questionModalId,
  } = useDeleteModal();

  const {
    showModal: showDeleteChatModal, RenderModal: DeleteChatModal, modalId: chatModalId,
  } = useDeleteModal();

  const resetAfterAskQuestion = () => {
    setCantSend(false);
    setQuestion('');
    setSelectedSuggestions([]);
  };

  const { data: userQuestions, isLoading: isGettingChatQuestions } = useGetChatQuestions(currentChat);
  const {
    data: userChats, isLoading: isUserChatsLoading, isFetching: isUserChatsFetching,
    fetchNextPage: fetchingUserChatsNextPage,
  } = useGetUserChats(
    (userChatsPagination.pageIndex + 1), userChatsPagination.pageSize,
  );
  const askGPTMutation = useAskGptMutation();
  const { mutateAsync: createChat, isLoading: isCreatingChat } = useCreateChatMutation();
  const userFeedbackMutation = useUserFeedbackMutation();
  const { mutateAsync: deleteChat, isLoading: isDeletingChat } = useDeleteChatMutation();
  const { mutateAsync: deleteQuestion, isLoading: isDeletingQuestion } = useDeleteQuestionMutation();

  React.useEffect(() => {
    if (userQuestions) {
      setData(userQuestions);
      if (userQuestions.length > 0) {
        setAiModel(userQuestions[userQuestions.length - 1].type);
      }
    }
  }, [userQuestions]);

  React.useEffect(() => {
    if (aiModels) {
      if (aiModels.length > 0) {
        setAiModel(aiModels[0].model);
      }
    }
  },
  [aiModels]);

  const resetAfterFeedback = () => {
    setOpenFeedbackModal(false);
    setFeedbackMessage('');
    setFeedbackAnswerId(0);
  };

  const handleAskGpt = async () => {
    if (question.trim() === '') {
      return;
    }
    try {
      if (!currentChat) {
        return;
      }
      setCantSend(true);
      const request = {
        question,
        chat_id: currentChat.id,
        selectedSuggestions,
        ai_model: aiModel,
        selectedDocuments,
      };
      await askGPTMutation.mutateAsync({ request });
      resetAfterAskQuestion();
    } catch (error) {
      resetAfterAskQuestion();
    }
  };

  const handleCreateChat = async () => {
    try {
      const request = {
        name: `Chat-${Math.floor(Math.random() * 1000000)}`,
      };

      await createChat(request);
      setCantSend(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleDeleteChat = async (chatId: number | null) => {
    if (chatId) {
      deleteChat({
        chatId,
      });
    }
  };

  const handleDeleteQuestion = async (questionId: number | null) => {
    const chatId = currentChat?.id;
    if (chatId && questionId) {
      deleteQuestion({ questionId, chatId });
    }
  };

  React.useEffect(() => {
    if (userChats) {
      setChats(userChats?.pages.map((page) => page.map((chat) => chat)).flat() || []);
      // if currentChat is not set, set the first chat as current chat
      // if currentChat doesn't exist in userChats, set the first chat as current chat
      if (!currentChat && userChats.pages.length > 0) {
        setCurrentChat(userChats.pages[0][0]);
      } else if (currentChat && userChats.pages.length > 0) {
        const chat = userChats.pages.map((page) => page.find((c) => c.id === currentChat?.id)).find((c) => c);
        if (!chat) {
          setCurrentChat(userChats.pages[0][0]);
        }
      }
      // if (userChats.length > 0 && userChatsPagination.pageIndex === 0) {
      //   setCurrentChat(userChats[0]);
      // } else if (userChats.length === 0) {
      //   // handleCreateChat();
      // }
    }
  }, [userChats]);

  const handleFeedbackSubmit = async () => {
    try {
      const request: UserFeedbackRequest = {
        feedback: selectedFeedback,
        feedbackComment: feedbackMessage,
      };
      await userFeedbackMutation.mutateAsync(
        { request, question_id: feedbackAnswerId, chat_id: currentChat?.id },
      );
      resetAfterFeedback();
    } catch (error) {
      resetAfterFeedback();
    }
  };

  const handleFeedback = (feedback: number, answer: CreateGptResponse) => {
    if (answer.feedback != null) {
      if (feedback === 1 && answer.feedback >= 1 && answer.feedback <= 2) {
        setSelectedFeedback(answer.feedback);
        if (answer.feedbackComment != null) {
          setFeedbackMessage(answer.feedbackComment);
        }
      }
      if (feedback === 1 && answer.feedback > 2 && answer.feedback <= 5) {
        setSelectedFeedback(1);
        setFeedbackMessage('');
      }
      if (feedback === 2 && answer.feedback > 2 && answer.feedback <= 5) {
        setSelectedFeedback(answer.feedback);
        if (answer.feedbackComment != null) {
          setFeedbackMessage(answer.feedbackComment);
        }
      }
      if (feedback === 2 && answer.feedback >= 1 && answer.feedback <= 2) {
        setSelectedFeedback(3);
        setFeedbackMessage('');
      }
    } else if (feedback === 1) {
      setSelectedFeedback(1);
    } else if (feedback === 2) {
      setSelectedFeedback(3);
    }
    setOpenFeedbackModal(true);
    setFeedbackType(feedback);
    setFeedbackAnswerId(answer.id);
  };

  const handelDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAiModel(event.target.value as string);
  };

  const getPositiveFeedbackByValue = (value: number) => positiveFeedbackTypes
    .find((feedback) => feedback.value === value);

  const getNegativeFeedbackByValue = (value: number) => negativeFeedbackTypes
    .find((feedback) => feedback.value === value);

  const getFeedbackValueByLabel = (label: string) => {
    const positiveFeedback = positiveFeedbackTypes.find((feedback) => feedback.label === label);
    if (positiveFeedback) {
      return positiveFeedback.value;
    }
    const negativeFeedback = negativeFeedbackTypes.find((feedback) => feedback.label === label);
    if (negativeFeedback) {
      return negativeFeedback.value;
    }
    return 0;
  };

  const searchTenant = () => {
    setFilteredTenant(tenantName);
  };

  const handelFeedbackDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFeedback(getFeedbackValueByLabel(event.target.value as string));
  };

  const handleResponseType = (response: LLMResponse) => {
    if ('answer' in response) {
      const data = response as CreateGptResponse;
      return (
        <Box className={classes.answerBox}>
          { data.errorMessage ? (
            <Box>
              <span style={{ color: 'red' }}>{data.errorMessage}</span>
            </Box>
          )
            : data.answerType !== 'TABLE' && typeof data.answer === 'string' ? (
              <MarkdownViewer markdown={data.answer} />
            ) : (
              <Box>
                { (typeof data.answer !== 'string') ? (
                  <GptDataGrid
                    columns={data.answer.columns}
                    data={data.answer.data}
                    index={data.answer.index}
                  />
                ) : null }
              </Box>
            )}
          <Box className={classes.feedBackContainer}>
            <ThumbDownOffAltIcon
              color={data.feedback !== undefined && (data.feedback > 2 && data.feedback <= 5)
                ? 'error' : 'disabled'}
              className={classes.feedbackButton}
              onClick={() => handleFeedback(2, data)}
            />
            <ThumbUpOffAltIcon
              color={data.feedback !== undefined && (data.feedback >= 1 && data.feedback <= 2)
                ? 'success' : 'disabled'}
              className={classes.feedbackButton}
              onClick={() => handleFeedback(1, data)}
            />
          </Box>
        </Box>
      );
    }
    const data = response as CreateLLMQuestionResponse;
    return (<LLMQuestion setInputValue={setQuestion} question={data} />);
  };

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const {
      target: { value },
    } = event;
    const filteredValue = (value as number[]).filter((val) => val !== null && val !== undefined);
    setSelectedDocuments(
      filteredValue,
    );
  };

  const handleDeleteDocument = (docId: number) => {
    setSelectedDocuments(selectedDocuments.filter((id) => id !== docId));
  };

  return (
    <Box className={classes.root}>
      <Box
        className={`${classes.sidebar} ${classes.leftSidebar} ${!leftSidebarOpen ? classes.sidebarClosed : ''}`}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateChat}
        >
          New Chat
        </Button>
        {chats?.map((chat) => (
          <Box
            key={chat.id}
            className={classes.chat}
            onClick={() => {
              if (!isDeletingChat && !isCreatingChat) { setCurrentChat(chat); setData([]); }
            }}
          >
            <Button
              color={currentChat?.id === chat.id ? 'primary' : 'default'}
              disabled={isDeletingChat || isCreatingChat}
            >
              {chat.name}
            </Button>
            <IconButton
              style={{ display: currentChat?.id === chat.id ? 'block' : 'none' }}
              size="small"
              className={classes.deleteChat}
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                showDeleteChatModal(chat.id);
              }}
              disabled={isDeletingChat || isCreatingChat}
            >
              <Delete fontSize="small" />
            </IconButton>
            <Box className={classes.chatDate} style={{ opacity: (isDeletingChat || isCreatingChat) ? 0.4 : 1 }}>
              {formatDateUSReadable(chat.createdAt)}
            </Box>
          </Box>
        ))}
        <Button
          onClick={() => fetchingUserChatsNextPage()}
          style={{
            display: (chats?.length === 0 || userChats?.pages[userChats?.pages.length - 1]?.length === 0)
              ? 'none' : 'block',
            textAlign: 'left',
          }}
          disabled={isUserChatsLoading || isUserChatsFetching || isDeletingChat || isCreatingChat}
        >
          {isUserChatsLoading || isUserChatsFetching
            ? <HourglassBottomIcon style={{ position: 'relative', top: '5px', marginRight: '8px' }} />
            : <ArrowDropDownIcon style={{ position: 'relative', top: '5px', marginRight: '8px' }} />}
          Show More
        </Button>
        {userChats?.pages[userChats?.pages.length - 1]?.length === 0 && (
          <Box style={{ textAlign: 'left', marginTop: '10px' }}>
            No more chats available
          </Box>
        )}
      </Box>
      <Box className={classes.mainContent}>
        <IconButton
          className={`${classes.sidebarToggle} ${classes.leftSidebarToggle}`}
          onClick={() => setLeftSidebarOpen(!leftSidebarOpen)}
        >
          {leftSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <Box className={classes.aiModelSwitch}>
          <IconButton
            size="small"
            className={classes.tipsButton}
            onClick={() => setOpenTipsModal(true)}
          >
            <TipsAndUpdatesIcon />
          </IconButton>
          <FormControl margin="none" style={{ width: '200px' }}>
            <Select
              value={aiModel}
              onChange={handelDropDownChange}
            >
              { aiModels?.map((model) => (
                <MenuItem
                  key={model.model}
                  disabled={!model.active}
                  value={model.model}
                >
                  {model.model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Box className={classes.responseView}>
            {/* Response view content goes here */}
            {initialData?.length === 0 && (
              <Box style={{
                color: blue[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-24px',
                marginLeft: '-24px',
                visibility: (cantSend || isGettingChatQuestions) ? 'hidden' : 'visible',
              }}
              >
                No chats available
              </Box>
            )}
            {initialData?.map((data) => (
              // eslint-disable-next-line react/no-array-index-key
              <>
                <Box
                  className={classes.answerContainer}
                  key={data.id}
                  onMouseEnter={() => setMouseOverAnswer(data.id)}
                  onMouseLeave={() => setMouseOverAnswer(0)}
                >
                  <IconButton
                    style={{ display: mouseOverAnswer === data.id ? 'flex' : 'none' }}
                    size="small"
                    className={classes.closeButton}
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      showDeleteQuestionModal(data.id);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                  <Box className={classes.questionBox}>{data.question}</Box>
                  {handleResponseType(data)}
                </Box>
              </>
            ))}
          </Box>
          <Paper className={classes.inputText}>
            {/* Input text content goes here */}
            <AutoCompleteTextFieldProps
              inputValue={question}
              setInputValue={setQuestion}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
              askGpt={handleAskGpt}
            />
            <Box className={classes.buttonBox}>
              <Button
                className={classes.sendButton}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAskGpt();
                }}
                disabled={cantSend || isGettingChatQuestions || isDeletingQuestion}
              >
                {(cantSend) ? '' : 'Send'}
              </Button>
              <CircularProgress
                size={24}
                style={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  visibility: (cantSend) ? 'visible' : 'hidden',
                }}
              />
            </Box>
          </Paper>
        </Box>
        <Box style={{
          color: blue[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-24px',
          marginLeft: '-24px',
          visibility: (isGettingChatQuestions || isDeletingQuestion) ? 'visible' : 'hidden',
        }}
        >
          {isGettingChatQuestions ? ' Getting chats...' : ''}
          {isDeletingQuestion ? 'Deleting...' : ''}
        </Box>
        <CircularProgress
          size={24}
          style={{
            color: blue[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '0px',
            marginLeft: '0px',
            visibility: (isGettingChatQuestions || isDeletingQuestion) ? 'visible' : 'hidden',
          }}
        />
        <IconButton
          className={`${classes.sidebarToggle} ${classes.rightSidebarToggle}`}
          onClick={() => setRightSidebarOpen(!rightSidebarOpen)}
        >
          {rightSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Box>
      <Box
        className={`${classes.sidebar} ${classes.rightSidebar} ${!rightSidebarOpen ? classes.sidebarClosed : ''}`}
      >
        <FormControl
          margin="none"
        >
          <TextField
            label="Search Tenant"
            variant="standard"
            type="text"
            value={tenantName}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                searchTenant();
              }
            }}
            onChange={(event) => setTenantName(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => searchTenant()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <FormControl style={{ minWidth: 154, marginTop: 20 }}>
          <InputLabel htmlFor="document-name">Select Document</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            className={classes.selectDocument}
            multiple
            value={selectedDocuments}
            labelWidth={100}
            onChange={handleChange}
            label="Select Document"
            inputProps={{
              name: 'document-name',
              id: 'document-id',
            }}
            disabled={!documents || !documents.items || documents.items.length === 0}
          >
            <option aria-label="None" defaultValue="" />
            {documents?.items?.map((doc : QADocument) => (
              <MenuItem
                key={doc.id}
                value={doc.id}
                style={getStyles(doc.id, selectedDocuments, theme)}
              >
                {doc.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box style={{ fontWeight: 'bold', marginTop: '20px' }}>Selected Documents:</Box>
        <List>
          {selectedDocuments.map((docId) => (
            <ListItem key={docId} style={{ borderBottom: '1px solid #ccc' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteDocument(docId)}
                >
                  <Delete />
                </IconButton>
                <Box style={{ fontWeight: 'bold', flexWrap: 'wrap', marginLeft: '10px' }}>
                  {cachedDocuments?.find((doc) => doc.id === docId)?.title}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      <Modal
        confirmColor="secondary"
        title="Tips for Using Our Chatbot:"
        body={(
          <Box style={{ overflowY: 'hidden' }}>
            <Box>
              <Box>
                <b>Usage:</b>
              </Box>
              <Box>
                <ul>
                  <li>New chat for new topic</li>
                  <li>Use the autocomplete for better results</li>
                  <li>Use drop down to switch between language models if you not receiving expected results</li>
                  <li>Use the feedback buttons to improve the chatbot in the future</li>
                  <li>Select documents from the right side panel to get answers from specific documents</li>
                </ul>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>Answers:</b>
              </Box>
              <Box>
                <ul>
                  <li>Chatbot doesn&apos;t respond to questions that are not relevant to Leasing Data</li>
                  <li>You will receive better results if the prompt is detailed</li>
                  <li>
                    By default, you&apos;ll receive the top 5 results. If you specify more,
                    the chatbot will adjust accordingly.
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        )}
        isOpen={openTipsModal}
        handleClose={() => { setOpenTipsModal(false); }}
        cancelText="Close"
        cancelAction={() => { setOpenTipsModal(false); }}
      />
      <Modal
        confirmColor="secondary"
        title={
          (
            <Box style={{ display: 'flex' }}>
              <Box>
                {(feedbackType === 1
                  ? <ThumbUpOffAltIcon />
                  : <ThumbDownOffAltIcon />
                )}
              </Box>
              <Box>Provide Feedback</Box>
            </Box>
          )
        }
        body={(
          <Box style={{ overflowY: 'hidden', width: '540px' }}>
            <FormControl margin="none" style={{ width: '540px' }}>
              <Select
                value={feedbackType === 1
                  ? (getPositiveFeedbackByValue(selectedFeedback)?.label || positiveFeedbackTypes[0].label)
                  : (getNegativeFeedbackByValue(selectedFeedback)?.label || negativeFeedbackTypes[0].label)}
                onChange={handelFeedbackDropDownChange}
              >
                {feedbackType === 1 && positiveFeedbackTypes.slice(0, 2).map((feedback) => (
                  <MenuItem
                    key={feedback.value}
                    value={feedback.label}
                  >
                    {feedback.label}
                  </MenuItem>
                ))}
                {feedbackType === 2 && negativeFeedbackTypes.slice(0, 3).map((feedback) => (
                  <MenuItem
                    key={feedback.value}
                    value={feedback.label}
                  >
                    {feedback.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={feedbackMessage}
              variant="outlined"
              label={(feedbackType === 1
                ? 'Add your feedback'
                : 'What was the issue with the response? How can it be improved?')}
              style={{ width: '540px', marginTop: '10px' }}
              onChange={(e) => setFeedbackMessage(e.target.value)}
            />
          </Box>
        )}
        isOpen={openFeedbackModal}
        handleClose={() => { setOpenFeedbackModal(false); setFeedbackMessage(''); }}
        confirmText="Submit feedback"
        confirmAction={() => handleFeedbackSubmit()}
        cancelText="Cancel"
        cancelAction={() => { setOpenFeedbackModal(false); setFeedbackMessage(''); }}
      />
      <DeleteQuestionModal
        body="Are you sure you want to delete this question?"
        confirmAction={() => handleDeleteQuestion(questionModalId)}
        title="Delete Question"
      />
      <DeleteChatModal
        body="Are you sure you want to delete this chat?"
        confirmAction={() => handleDeleteChat(chatModalId)}
        title="Delete Chat"
      />
    </Box>
  );
};

export default GptUI;
